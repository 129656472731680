var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v("My Assignments")])]),_c('b-alert',{attrs:{"variant":"primary","show":""}},[_c('div',{staticClass:"alert-body"},[_vm._v(" Click on the + sign to view details ")])]),_c('v-client-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.load),expression:"load"}],attrs:{"columns":_vm.columns,"options":_vm.options},scopedSlots:_vm._u([{key:"child_row",fn:function(ref){
var row = ref.row;
return _c('div',{},[_c('aside',[_c('legend',[_vm._v("Assignment details")]),_c('span',{domProps:{"innerHTML":_vm._s(row.assignment_details)}})])])}},{key:"teacher",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(row.subject_teacher.staff.user.first_name + ' ' + row.subject_teacher.staff.user.last_name)+" ")])}},{key:"created_at",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(_vm.moment(row.created_at).format('MMM Do YYYY'))+" ")])}},{key:"deadline",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(_vm._s(_vm.moment(row.deadline).format('MMM Do YYYY [at] h:mm a'))+" ")])}},{key:"action",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"demo-inline-spacing"},[(row.is_marked === '0')?_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.viewStudentResponses(row)}}},[_vm._v(" Attempt ")]):_c('b-button',{attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.viewStudentResponses(row)}}},[_vm._v(" View Details ")])],1)}}]),model:{value:(_vm.assignments),callback:function ($$v) {_vm.assignments=$$v},expression:"assignments"}})],1),_c('b-modal',{attrs:{"cancel-only":"","cancel-title":"Close","centered":"","size":"lg","title":"Attempt Assignment"},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}},[_c('b-card-text',[_c('div',{staticStyle:{"height":"500px","overflow":"auto"}},[_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.selected_assignment.assignment_details)}})]),(_vm.can_edit)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadModal),expression:"loadModal"}]},[_c('h4',[_vm._v("Type your answer")]),_c('quill-editor',{model:{value:(_vm.form.student_answer),callback:function ($$v) {_vm.$set(_vm.form, "student_answer", $$v)},expression:"form.student_answer"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitAssignment()}}},[_vm._v(" Submit ")])],1):_c('div',[(_vm.student_assignment !== null)?_c('div',[_vm._v(" Your Answer: "),_c('br'),_c('aside',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.student_assignment.student_answer)}})]),_c('hr'),_c('strong',[_vm._v("Your Score: "+_vm._s(_vm.student_assignment.score))]),_c('hr'),_vm._v(" Teacher's Remark: "),_c('aside',[_vm._v(_vm._s(_vm.student_assignment.remark))]),_c('br')]):_c('div',[_c('el-alert',{attrs:{"closable":false,"type":"error"}},[_vm._v(" This assignment is awaiting teacher's response ")])],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }